.wrapForm {
  box-shadow: 0 0 10px 1px #ccc;
  border-radius: 6px;
  margin: 24px 3px;
  padding: 20px 25px 25px;
}
.inputForm {
  padding: 0 !important;
  font-size: 25pt !important;
  border: none !important;
  font-weight: 700 !important;
  color: #333 !important;
  height: auto !important;
  resize: none !important;
  line-height: 1.2 !important;
}
.inputForm::placeholder {
  color: #7d7b7d;
  font-weight: 700;
  word-wrap: break-word;
  cursor: text;
  min-width: 10px;
  font-size: 25pt;
}
.inputForm2 {
  font-size: 12pt !important;
  border: solid thin #333;
  font-weight: 500 !important;
  color: #333 !important;
  /* height: 33px !important; */
  resize: none !important;
  line-height: 1.2 !important;
  padding: 10px 15px !important;
}
.inputForm2::placeholder {
  color: #7d7b7d;
  font-weight: 300;
  word-wrap: break-word;
  cursor: text;
  min-width: 10px;
  font-size: 12pt;
}
.inputInfo {
  font-size: 12px;
  display: inherit;
  color: rgb(204, 204, 204);
}
.inputTeaser {
  padding: 0 !important;
  font-size: 16pt !important;
  border: none !important;
  font-weight: 400 !important;
  color: #333 !important;
  height: auto !important;
  resize: none !important;
  line-height: 1.2 !important;
  margin-bottom: 4px;
}
.inputTeaser::placeholder {
  color: #7d7b7d;
}
.reviewImg {
  width: 100%;
  margin-bottom: 5px;
  border: solid thin #b9b6b6;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
[type="file"] + label {
  background: #fff;
  border: dashed 2px #b9b6b6;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  color: #ff7a00;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 14pt;
  font-weight: 400;
  outline: none !important;
  padding: 50px 0;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
[type="file"] + label:hover {
  background-color: #fffefe;
}
.btnDeleteImg {
  position: absolute;
  right: 55px;
  margin-top: 15px;
}
label {
  margin: 0 !important;
}
.btnSave {
  background: #ff7000 !important;
  padding: 15px 30px !important;
  font-size: 12pt;
  border: none !important;
}
.messageError {
  margin: 10px 0 0;
  padding: 8px 10px;
  border: solid 1px #e20101;
  color: #d21c06;
  font-size: 10pt;
  transition: all 0.3s;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #ff7000;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.addSection {
  border: 1px solid #e6001b;
  padding: 8px 16px;
  border-radius: 25px;
  font-size: 14px;
  color: #e6001b;
  font-weight: 700;
  margin-top: 20px;
}
.inputFormSection {
  padding: 0 !important;
  font-size: 20pt !important;
  border: none !important;
  font-weight: 700 !important;
  color: #333 !important;
  height: auto !important;
  resize: none !important;
  line-height: 1.2 !important;
}
.iconClose {
  position: absolute;
  right: 40px;
  margin-top: 25px;
  font-size: 20pt;
  color: #e6001b;
}
.radio + label {
  padding-top: 8px !important;
  margin-left: 14px
}
.radio:checked + label:before,
.radio:not(:checked) + label:before {
  top: 10px !important;
}
.radio:checked + label:after,
.radio:not(:checked) + label:after {
  top: 13px !important;
}
