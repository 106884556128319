/* import 'bootstrap/dist/css/bootstrap.min.css'; */
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit !important;
}

* {
  outline: none !important;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
  /* list-style: none; */
}
a:focus,
a:hover {
  text-decoration: none !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 150px !important;
}
.css-1uccc91-singleValue {
  color: #6a757e !important;
  font-weight: 700;
}
table th,
table td {
  font-size: 10pt;
}
